import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import CTA from "../components/CTA"
import PageHeading from "../components/PageHeading"
import { Content } from "../components/Content"

type TemplateProps = {
  page: {
    title: string
    subtitle: string
    support: string
    heroImage: string
    firstSection: {
      heading: string
      support: string
      content: string
      image: string
    }
    secondSection: {
      heading: string
      support: string
      content: string
      image: string
    }
    callToAction: {
      heading
      subHeading
      action
    }
  }
}
const squareDots = require("../images/blue-dots.svg")
const rectDots = require("../images/dots.svg")

export function ProcesosDeCalidadTemplate({ page }: TemplateProps) {
  const {
    title,
    subtitle,
    support,
    firstSection,
    secondSection,
    heroImage,
    callToAction,
  } = page
  return (
    <>
      {/* start hero */}
      <section className="relative pb-8 overflow-hidden min-h-auto">
        <div className="flex flex-wrap max-w-5xl px-4 pt-24 pb-8 mx-auto md:pt-32">
          <div className="w-full md:w-1/2">
            <div className="z-30 md:mt-16 md:pr-8">
              <p className="mt-6 text-lg font-semibold tracking-wide text-orange-500 font-display">
                {support}
              </p>
              <div className="mt-4">
                <PageHeading>{title}</PageHeading>
              </div>
              <p className="mt-6 text-lg text-green-100 w-96">{subtitle}</p>
              <div className="mt-12">
                <CTA url="/contactanos">Contacta un asesor</CTA>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end w-full md:w-1/2">
            <img
              src={heroImage}
              className="object-cover mx-auto mt-16 md:mx-0 md:mt-0"
              style={{
                width: 384,
                height: 512,
              }}
            />
          </div>
        </div>
        <div className="gradient-bg bg-gradient-to-br from-green-300 via-green-500 to-green-700"></div>
        <div
          className="absolute right-0 top-auto h-24 px-16 bg-white width-full"
          style={{ transform: "rotate(2deg)", zIndex: -2, left: -20 }}
        ></div>
      </section>
      {/* end hero */}

      {/* start feature 1 */}
      <section className="grid w-full grid-cols-1 gap-4 px-4 mx-auto mt-10 overflow-hidden max-w-7xl md:grid-cols-2 md:mt-32">
        <div className="relative flex justify-end w-full pb-16 md:px-16">
          <img
            className="absolute"
            src={squareDots}
            alt=""
            style={{
              bottom: -40,
              left: 120,
              zIndex: -10,
            }}
          />
          <img
            src={firstSection.image}
            className="object-cover mx-auto mt-16 md:mx-0 md:mt-0"
            style={{
              width: 384,
              height: 512,
            }}
          />
        </div>
        <div className="w-full row-start-1 md:row-start-auto md:pl-12">
          <div className="md:max-w-md">
            <p className="tracking-wide text-gray-600 font-display">
              {firstSection.support}
            </p>
            <h2 className="mt-2 section-heading">{firstSection.heading}</h2>
            <Content
              content={firstSection.content}
              className="mt-4 text-base text-gray-700 cms-content"
            />
          </div>
        </div>
      </section>

      {/* start feature 2 */}
      <section className="grid w-full grid-cols-1 gap-4 px-4 pt-16 mx-auto mt-10 overflow-hidden max-w-7xl md:grid-cols-2 md:mt-24">
        <div className="w-full md:pr-12">
          <div className="ml-auto md:max-w-sm">
            <p className="tracking-wide text-gray-600 font-display">
              {secondSection.support}
            </p>
            <h2 className="mt-2 section-heading">{secondSection.heading}</h2>
            <Content
              content={secondSection.content}
              className="mt-6 text-base text-gray-700 cms-content"
            />
          </div>
        </div>
        <div className="relative w-full">
          <img
            src={secondSection.image}
            className="object-cover mx-auto mt-16 md:mx-0 md:mt-0"
            style={{
              width: 384,
              height: 512,
            }}
          />
          <img
            className="absolute"
            src={rectDots}
            alt=""
            style={{
              top: -40,
              right: 160,
              zIndex: -10,
            }}
          />
        </div>
      </section>
      {/* end feature 2 */}

      {/* CTA */}
      <section className="cta-section bg-gradient-to-br from-green-400 via-green-500 to-green-600">
        <div className="flex flex-col items-center justify-center w-full max-w-5xl px-4 py-8 mx-auto mt-10 overflow-hidden">
          <div>
            <hr className="separator" />

            <h2 className="max-w-lg text-4xl font-bold leading-tight text-white md:text-5xl font-display">
              {callToAction.heading}
            </h2>
            <p
              className="w-auto max-w-lg mt-6 text-lg leading-6 text-white"
              dangerouslySetInnerHTML={{ __html: callToAction.subHeading }}
            ></p>
            <div className="my-6">
              <CTA url="/contactanos">{callToAction.action}</CTA>
            </div>
          </div>
        </div>
        <div className="cta-cut-top"></div>
        <div className="cta-cut-bottom"></div>
        <div className="cta-cut-right"></div>
      </section>
      {/* End Form section */}
      {/* end CTA */}
    </>
  )
}

type PageProps = {
  data: any
}

export default function ProcesosDeCalidad({ data }: PageProps) {
  const { frontmatter: page } = data.pageData.edges[0].node

  return (
    <Layout>
      <SEO title={page.seo.title} description={page.seo.description} />
      <ProcesosDeCalidadTemplate page={page} />
    </Layout>
  )
}

export const productosPageQuery = graphql`
  query ProcesosDeCalidadQuery {
    pageData: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "procesos-de-calidad-page" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            support
            heroImage
            firstSection {
              heading
              support
              content
              image
            }
            secondSection {
              heading
              support
              content
              image
            }
            callToAction {
              heading
              subHeading
              action
            }
            seo {
              browserTitle
              title
              description
            }
          }
        }
      }
    }
  }
`
